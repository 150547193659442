.Background {
  transition: background-color 0.5s ease-in-out;
}

.bg-dark::after {
  content: '';
  box-sizing: content-box;
  position: fixed;
  top: -50%;
  left: 50%;
  width: 1800px;
  height: 1800px;
  border-radius: 100%;
  opacity: 0.5;
  background: radial-gradient(circle, rgba(29, 167, 224, 1) 0%, rgba(29, 167, 224, 0) 70%);
}

.bg-light::after {
  content: '';
  box-sizing: content-box;
  position: fixed;
  top: -50%;
  left: -50%;
  width: 2300px;
  height: 2300px;
  border-radius: 100%;
  opacity: 0.5;
  background: radial-gradient(circle, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 70%);
}

.bg-dark::before {
  content: '';
  box-sizing: content-box;
  position: fixed;
  z-index: 1;
  bottom: 0;
  right: 50%;
  width: 1800px;
  height: 1800px;
  border-radius: 100%;
  opacity: 0.4;
  background: radial-gradient(circle, rgba(91, 41, 140, 1) 0%, rgba(91, 41, 140, 0) 70%);
}

.bg-light::before {
  content: '';
  box-sizing: content-box;
  position: fixed;
  z-index: 0;
  bottom: -50%;
  right: -50%;
  width: 2000px;
  height: 2000px;
  border-radius: 100%;
  opacity: 0.2;
  background: radial-gradient(circle, rgb(55, 84, 188) 0%, rgba(48, 100, 164, 0) 70%);
}

.bg-blue {
  background-color: var(--primary);
}

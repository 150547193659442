@font-face {
  font-family: 'Mona Sans';
  src: url('../public/fonts/Mona-Sans.woff2') format('woff2 supports variations'),
    url('../public/fonts/Mona-Sans.woff2') format('woff2-variations');
  font-weight: 200 900;
  font-stretch: 75% 125%;
}

@font-face {
  font-family: 'Hubot Sans';
  src: url('../public/fonts/hubot-sans.woff2') format('woff2 supports variations'),
    url('../public/fonts/hubot-sans.woff2') format('woff2-variations');
  font-weight: 200 900;
  font-stretch: 75% 125%;
}

.menu-icon-wrapper {
  border: 0;
  background-color: transparent !important;
  height: 26px;
  width: 34px;
  align-items: center;
  justify-content: center;
  z-index: 4;
  /* cursor: pointer; */
  position: relative;
}

.menu-icon-wrapper i {
  height: inherit;
  min-width: inherit;
  justify-content: space-around;
}

.menu-icon-wrapper:hover span:nth-child(1) {
  transform: translateX(0px);
}

.menu-icon-wrapper:hover span:nth-child(3) {
  transform: translateX(0px);
}

.active .menu-icon-wrapper {
  justify-content: center;
}

.menu-icon-wrapper i span {
  display: block;
  height: 2px;
  width: 27px;
  background-color: var(--black);
  transition: transform 0.2s ease-in-out;
  /* cursor: pointer; */
}

.menu-icon-wrapper i.menu-white-color span {
  background-color: var(--white);
}

.menu-icon-wrapper span:nth-child(1) {
  transform: translateX(-4px);
}

.menu-icon-wrapper span:nth-child(2) {
  transform: scaleY(1);
}

.menu-icon-wrapper span:nth-child(3) {
  transform: translateX(4px);
}

.active .menu-icon-wrapper span:nth-child(1) {
  transform: translateY(0px) translateY(9px) rotateZ(45deg);
}

.active .menu-icon-wrapper span:nth-child(2) {
  transform: scaleX(0);
}

.active .menu-icon-wrapper span:nth-child(3) {
  transform: translateX(0px) translateY(-9px) rotateZ(-45deg);
}

@media (min-width: 540px) {
  .active .menu-icon-wrapper {
    transform: translateX(-1rem);
  }
}

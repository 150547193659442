.menu-fixed {
  position: fixed;
  width: calc(100% - var(--fixed-menu-rm));
  display: flex;
  justify-content: space-between;
  z-index: 4;
}

.dhd-logo-wrapper {
  display: flex;
  width: 10rem;
  /* cursor: pointer; */
}

.dhd-logo-wrapper svg path {
  transition: all 0.3s;
}

.menu-icon-wrapper {
  display: flex;
}

.menu-icon-wrapper span {
  background-color: var(--white);
}

.sandwich-menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}

.logo {
  &:hover::before,
  &:focus::before {
    content: none;
  }
}

@media (max-width: 720px) {
  .dhd-logo-wrapper {
    width: 8rem;
  }
}

footer {
  i svg path,
  i svg circle,
  i svg rect,
  i svg polygon {
    fill: var(--black);
  }

  i {
    display: inline-block;
    width: 200px;
    align-self: center;
    margin: 1rem;
  }
  .logos {
    margin-top: 4rem;
  }
  .contact {
    margin-bottom: 35vh;
  }
  .footer-content-wrapper {
    flex-direction: column;
    align-items: center;
  }
}

.dropdown-menu {
  position: absolute;
  top: 2rem;
  left: 0;
  pointer-events: none;
  background-color: var(--black);
  flex-direction: row;
}

.dropdown-menu a {
  color: var(--white);
  display: flex;
  align-items: center;
}

.dropdown-menu-row-one,
.dropdown-menu-row-two {
  padding: 1.5rem 0rem;
}

li.active .dropdown-menu {
  // opacity: 1;
  pointer-events: all;
}

.dropdown-menu li {
  margin: 0 !important;
  padding: 0.4rem 0;
  // height: inherit !important;
  white-space: nowrap;
}

.dropdown-menu i {
  display: inline-block;
  position: relative;
  transform: scale(0.8) translateX(-3px);
  opacity: 0;
  transition: transform 0.2s ease-in-out;
  padding-left: 0.3rem;
}

.dropdown-menu a:hover i,
.dropdown-menu a:focus i,
.dropdown-menu a.active i {
  opacity: 1;
  transform: scale(0.8) translateX(0px);
}

.full-screen-menu-wrapper .dropdown-menu {
  background-color: transparent;
  position: relative;
  padding: 0;
}

@media (max-width: 540px) {
  .dropdown-menu .dropdown-menu-row-one {
    padding-right: 0.2rem;
  }
  .dropdown-menu a {
    font-size: 0.8rem;
  }
  .full-screen-menu-wrapper .active .dropdown-menu {
    position: relative;
    padding: 0;
  }
  .full-screen-menu-wrapper .dropdown-menu .dropdown-wrapper {
    flex-direction: column;
    .dropdown-menu-row-one {
      padding-bottom: 0;
    }
    .dropdown-menu-row-two {
      padding: 0;
    }
  }
}

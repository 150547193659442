.secondery-menu {
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 700;
  padding-top: 0.8rem;
}

.secondery-menu li {
  display: block;
  padding: 0.3rem 0;
}

.secondery-menu a {
  color: var(--white);
  text-decoration: none;
}

.secondery-menu i {
  display: inline-block;
  transform: scale(0.8) translateX(-3px) translateY(-1px);
  opacity: 0;
  transition: transform 0.2s ease-in-out;
  padding-left: 0.3rem;
}

.secondery-menu a:hover i,
.secondery-menu a:focus i,
.secondery-menu a.active i {
  opacity: 1;
  transform: scale(0.8) translateX(0px) translateY(-1px);
}

@media (max-width: 540px) {
  .secondery-menu a {
    font-size: 1rem;
  }
  .secondery-menu {
    padding-top: 0rem;
    padding-left: 0rem !important;
    margin: 1rem 0;
  }
  .secondery-menu li {
    padding: 0;
  }
}

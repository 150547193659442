.menu-full-screen .menu-wrapper ul {
  display: flex !important;
}

.menu-full-screen {
  background-color: var(--black);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  transform-origin: right;
  overflow: hidden;
}

.menu-full-screen::before {
  content: '';
  box-sizing: content-box;
  position: fixed;
  bottom: -50%;
  left: 50%;
  width: 1800px;
  height: 1800px;
  border-radius: 100%;
  opacity: 0.4;
  background: radial-gradient(circle, rgb(29, 167, 224) 0%, rgba(29, 167, 224, 0) 70%);
}

.menu-full-screen::after {
  content: '';
  box-sizing: content-box;
  position: fixed;
  top: -50%;
  right: 50%;
  width: 1800px;
  height: 1800px;
  border-radius: 100%;
  opacity: 0.5;
  background: radial-gradient(circle, rgb(91, 41, 140) 0%, rgba(91, 41, 140, 0) 70%);
}

.full-screen-menu-wrapper nav ul li {
  color: var(--white);
}

.full-screen-menu-wrapper nav i span {
  background-color: var(--white);
}

.full-screen-menu-wrapper a {
  color: var(--white);
}

.full-screen-menu-wrapper .menu-wrapper {
  justify-content: flex-start;
  position: relative;
  padding: 0;
  top: 0;
}

.full-screen-menu-wrapper .dropdown-menu {
  flex-direction: row;
}

.full-screen-menu-wrapper ul {
  flex-direction: column;
  position: relative;
  top: 0;
}

.full-screen-menu-wrapper > .menu-wrapper > ul {
  padding-left: 0;
}

.full-screen-menu-wrapper > .menu-wrapper > ul li {
  white-space: nowrap;
  margin: 0;
}

.full-screen-menu-wrapper .menu-wrapper > ul > li {
  font-size: min(6vw, 2.5rem);
  height: auto;
  padding: 0.5rem 0;
}

.full-screen-menu-wrapper .menu-wrapper > ul > li:nth-child(1) {
  overflow: hidden;
}

.full-screen-menu-wrapper .menu-wrapper ul li a .icon-plus {
  transform: translateY(-4px);
}

.menu-full-screen .footer-menu-wrapper {
  margin: auto;
  padding: 0;
  width: 100%;
}
.menu-full-screen .scroll-wrapper {
  height: 100%;
}

.menu-full-screen .footer-menu-wrapper {
  transform: translateY(-15px);
  justify-content: center;
}

.btn.btn-none {
  &::after,
  &::before {
    border: none 0;
  }
  &:hover,
  &:focus {
    background-color: transparent !important;
  }
}

@media (max-width: 720px) {
  .menu-full-screen .scroll-wrapper {
    height: 90%;
    padding-top: 10vh;
  }

  .menu-full-screen .scroll-wrapper .menu-block {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .menu-full-screen .footer-menu-wrapper {
    margin-top: 1rem;
  }
  .menu-full-screen .footer-menu-wrapper {
    transform: translateY(-5px);
  }
}

.footer-menu-wrapper {
  z-index: 2;
  margin: 0 4rem 2rem 4rem;
}

footer .footer-menu-wrapper {
  margin: 0;
}

.footer-menu-wrapper ul {
  justify-content: flex-end;
  margin: 0;
}

footer .footer-menu-wrapper ul {
  color: var(--black);
  font-size: 0.8rem;
  li a {
    &:hover,
    &:focus,
    &:active {
      color: var(--primary);
    }
  }
}

.footer-line {
  display: flex;
  height: 1px;
  width: 100%;
  background-color: var(--black);
}

.menu-full-screen .footer-line {
  background-color: var(--white);
}

.menu-full-screen .footer-menu-wrapper ul li a,
.menu-full-screen .footer-menu-wrapper p {
  color: var(--white);
  white-space: nowrap;
  font-size: 0.9rem;
}

.menu-full-screen .footer-menu-wrapper p {
  margin: 0;
}

.menu-full-screen .footer-menu-wrapper ul,
footer ul {
  padding: 0;
}

footer .footer-menu-wrapper p {
  color: var(--black);
  white-space: nowrap;
  font-size: 0.9rem;
  margin: 0;
}

@media (max-width: 540px) {
  .menu-full-screen .footer-menu-wrapper ul li a {
    font-size: 0.7rem;
  }
  .menu-full-screen .footer-menu-wrapper p {
    font-size: 0.7rem;
  }
}

$border: 3px solid var(--white);

.btn {
  text-decoration: none;
  border-radius: 0 !important;
  padding: 1rem 2rem !important;
  position: relative;
  z-index: 1;
  font-size: 1.4rem !important;
  display: inline-flex !important;
  align-items: center !important;
  text-transform: uppercase !important;
  white-space: nowrap;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);

  &:hover,
  &:focus {
    background-color: rgb(18, 106, 144) !important;
    color: var(--white);
  }
}

.btn {
  &::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    display: block;
    border-top: 3px solid var(--white);
    border-bottom: 3px solid var(--white);
    transform-origin: center;
    transform: scaleX(0%);
    transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 2;
    background: transparent !important;
  }
  &::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    display: block;
    border-left: 3px solid var(--white);
    border-right: 3px solid var(--white);
    transform-origin: center;
    transform: scaleY(0%);
    transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 2;
    background: transparent !important;
  }
}

.bg-blue ~ main .btn,
.bg-blue ~ footer .btn {
  &::after {
    transform: scaleX(100%);
  }
  &::before {
    transform: scaleY(100%);
  }
}

.bg-blue ~ main .btn:hover::after,
.bg-blue ~ main .btn:focus::after,
.bg-blue ~ footer .btn:hover::after,
.bg-blue ~ footer .btn:focus::after,
.btn-secondary:hover::after {
  transform: scaleX(0%) !important;
}
.bg-blue ~ main .btn:hover::before,
.bg-blue ~ main .btn:focus::before,
.bg-blue ~ footer .btn:hover::before,
.bg-blue ~ footer .btn:focus::before,
.btn-secondary:hover::before {
  transform: scaleY(0%) !important;
}

.btn:hover::after,
.btn:focus::after {
  transform: scaleX(100%);
}
.btn:hover::before,
.btn:focus::before {
  transform: scaleY(100%);
}

.btn-primary {
  background-color: var(--primary) !important;
  border-color: transparent !important;
  color: var(--white);
}

.btn-secondary {
  background-color: transparent !important;
  color: var(--primary);
  &::before {
    border-left: 3px solid var(--primary) !important;
    border-right: 3px solid var(--primary) !important;
    background: transparent !important;
    transform: scaleY(100%);
  }
  &::after {
    border-top: 3px solid var(--primary) !important;
    border-bottom: 3px solid var(--primary) !important;
    background: transparent !important;
    transform: scaleX(100%);
  }
}

.btn svg {
  margin-left: 0.4rem;
}

.bg-blue ~ main .btn {
  &.btn-primary,
  &.btn-secondary {
    color: var(--white);
  }
}

footer .btn.btn-secondary,
.bg-blue ~ main .btn.btn-secondary {
  color: var(--black);
  border: none !important;
  &::before {
    border-left: 3px solid var(--black) !important;
    border-right: 3px solid var(--black) !important;
    background: transparent !important;
  }
  &::after {
    border-top: 3px solid var(--black) !important;
    border-bottom: 3px solid var(--black) !important;
    background: transparent !important;
  }
  &:hover,
  &:focus,
  &:active {
    color: var(--white);
  }
}

@media (max-width: 540px) {
  .btn {
    width: 100%;
    justify-content: center;
  }
}

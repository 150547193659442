// Custom.scss

// Option B: Include parts of Bootstrap
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import '../node_modules/bootstrap/scss/functions';

// 2. Include any default variable overrides here
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1000px,
  xxl: 1320px,
);
$grid-gutter-width: 24px;

// 3. Include remainder of required Bootstrap stylesheets
@import '../node_modules/bootstrap/scss/variables';

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import '../node_modules/bootstrap/scss/maps';
@import '../node_modules/bootstrap/scss/mixins';
@import '../node_modules/bootstrap/scss/root';

// 6. Optionally include any other parts as needed
@import '../node_modules/bootstrap/scss/utilities';
@import '../node_modules/bootstrap/scss/reboot';
// @import "../node_modules/bootstrap/scss/type";
@import '../node_modules/bootstrap/scss/images';
@import '../node_modules/bootstrap/scss/containers';
@import '../node_modules/bootstrap/scss/grid';
@import '../node_modules/bootstrap/scss/helpers';
@import '../node_modules/bootstrap/scss/transitions';
// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import '../node_modules/bootstrap/scss/utilities/api';
// 8. Add additional custom code here

html {
  font-family: 'Mona Sans', 'Hubot Sans';
  font-variation-settings: 'ital' 10, 'wdth' 120;
}

body {
  cursor: default !important;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body-light-bg);
}

a,
button {
  cursor: pointer !important;
}

footer {
  position: relative;
  z-index: 1;
}

main {
  position: relative;
  padding: 1.5rem var(--main-container-padding);
  overflow-x: hidden;
}

#canvas-container,
#canvas-container-menu-fullscreen {
  z-index: 0;
  div {
    touch-action: auto !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

section {
  position: relative;
  z-index: 1;
}

h3,
h4 {
  font-weight: 400;
  font-stretch: 85%;
  font-variation-settings: 'ital' 10, 'wdth' 114;
}

h1 {
  font-weight: 400;
  font-stretch: 100%;
  font-variation-settings: 'ital' 10, 'wdth' 130;
  font-size: min(10vw, 4.5rem);
  line-height: min(10vw, 4.4rem);
  text-transform: uppercase;
  color: var(--black);
}

h2 {
  font-size: min(6.5vw, 3.6rem);
  line-height: min(8.55vw, 3.4rem);
  text-transform: uppercase;
  color: var(--black);
  font-weight: 300;
  font-stretch: 85%;
  font-variation-settings: 'ital' 10, 'wdth' 120;
}

.bg-dark ~ main h2,
.bg-dark ~ main h3,
.bg-dark ~ main h4,
.bg-dark ~ main h5,
.bg-dark ~ main h6,
.bg-dark ~ main p,
.bg-dark ~ main span {
  color: var(--white);
}

.container {
  padding: 0;
}

.bg-dark ~ main h4 {
  font-weight: 700;
}

a {
  position: relative;
  display: inline-flex;
  color: var(--black);
  text-decoration: none;
  text-transform: uppercase;
  transform-origin: center;
  z-index: 1;
  // cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    display: block;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    background-color: var(--primary);
    z-index: -1;
    transform: scaleX(0%);
    transition: transform 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  &:hover,
  &:focus,
  &.active,
  &:hover span,
  &:focus span,
  &.active span {
    color: var(--white);
    &::before {
      transform: scaleX(100%);
    }
  }
}

footer a {
  &::before {
    background-color: var(--black);
  }
  &:hover,
  &:focus,
  &.active {
    color: var(--primary);
  }
}

span,
p,
li,
h6 {
  font-variation-settings: 'ital' 10, 'wdth' 120;
  color: var(--black);
}

h5 {
  font-variation-settings: 'ital' 10, 'wdth' 120;
}

li {
  display: inline-flex;
}

p,
span,
data {
  font-size: 1.1rem;
}

data {
  font-weight: 800;
}

li {
  position: relative;
  list-style-type: none;
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 3px solid;
  opacity: 1;
  margin-top: 3rem;
}

pre {
  display: none;
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bg-dark {
  background-color: var(--black);
}

.bg-dark h1,
.bg-dark h2 {
  color: var(--white);
}

.bg-light {
  background-color: var(--body-light-bg) !important;
}

.mt-30 {
  margin-top: 30vh;
}

.mb-30 {
  margin-bottom: 30vh;
}

.mt-15 {
  margin-top: 15vh;
}

.mb-15 {
  margin-bottom: 15vh;
}

footer {
  padding: var(--main-container-padding);
}

:root {
  --primary-with-opacity: 29, 167, 224;
  --primary: rgb(29, 167, 224);
  --black: #172429;
  --white: #eef1f2;
  --alret: #cd5082;
  --body-light-bg: #cadeed;
  --fixed-menu-rm: 8rem;
  --main-container-padding: 4rem;
}

@media (max-width: 1320px) {
  :root {
    --fixed-menu-rm: 4rem;
    --main-container-padding: 2rem;
  }
}

@media (max-width: 540px) {
  h3,
  h2 {
    font-weight: 600;
  }
  :root {
    --fixed-menu-rm: 2rem;
    --main-container-padding: 1rem;
  }
  main {
    padding: 0.5rem var(--main-container-padding);
  }
  h1 b {
    font-size: min(12vw, 6rem);
  }
}
